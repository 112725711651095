<template>
  <div>
    <div class="videoMask">
      <div class="videoBox">
        <span class="close" @click="handleClose"><img src="https://thcdn.gggamedownload.com/source/assets/close.png" alt=""></span>
        <div class="video">
          <div v-show="!isError">
            <video
              id="videobox"
              ref="videoRef"
              class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9"
              controls
              preload="auto"
              webkit-playsinline="true"
              playsinline="true"
              type="application/x-mpegURL"
              allowsInlineMediaPlayback="YES"
              webview.allowsInlineMediaPlayback="YES"
              width="100%"
              x5-video-player-fullscreen="true"
              :poster="posterSrc"
            >
              <source id="sourceBox" :src="videoSrc">
              <p class="vjs-no-js">不支持播放</p>
            </video>
          </div>
          <div v-show="isError" class="errorTip"><p>视频出错了！</p></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
  props: {
    videoSrc: {
      type: String,
      default: "https://thcdn.gggamedownload.com/apk/lwop.mp4"
    },
    posterSrc: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isError: false,
      player: ''
    }
  },
  mounted() {
    // 为避免在初始化video时播放源是空的，报播放源错误，需要先给source 的src赋值
    this.player = videojs('videobox', {
      bigPlayButton: true,
      textTrackDisplay: true,
      posterImage: true,
      errorDisplay: false,
      controlBar: false,
      playbackRates: [0.5, 1, 1.5, 2],
      ControlBar: {
        customControlSpacer: true
      }
    },
    function onPlayerReady() {
      this.play();
    }
    )
    this.player.width('100%') // 设置播放器宽度
    this.player.play()
  },
  beforeDestroy() {
    const videoDom = this.$refs.videoRef; // 不能用document 获取节点
    videojs(videoDom).dispose(); // 销毁video实例，避免出现节点不存在 但是flash一直在执行,也避免重新进入页面video未重新声明
  },
  methods: {
    handleClose() {
      this.$parent.showMask = false
    }
  }
};
</script>
<style lang="scss" scoped>
 .videoMask {
          position: fixed;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.9);
          z-index: 1000;
          .videoBox {
            position: relative;
            width: 55%;
            height: 60%;
            .close {
              cursor: pointer;
              position: absolute;
              right: 0;
              top: 0;
              color: #fff;
              transform: translateX(100%) translateY(-100%);
            }
            video {
              width: 100%;
              // height: 100%;
            }
          }
        }
</style>>

